import React, { useState, useEffect } from 'react';
import newbanner from "../Assets/newbanner.png";
import axios from 'axios';
import { toast } from 'react-toastify';
import { baseUrl } from '../utils/data';
import load from '../Assets/newlogo.png';
import SmallCom from './SmallCom';

const StepTwo = ({ step, handleNext, handleBack }) => { // Add handleBack to props
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData) {
      setName(userData.name);
      setEmail(userData.email);
      setMobile(userData.mobile);
    }
  }, []);

  const updateUser = async () => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (!userData) {
      toast.error("Please Login First.");
      return;
    }
    if (!name || !email || !mobile) {
      toast.error("All fields are required");
      return;
    }
    setLoading(true);
    try {
      const res = await axios.put(
        `${baseUrl}/user/update`,
        { name, email, mobile, userId: userData?._id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (res?.status === 200) {
        toast.success("Profile updated successfully");
        localStorage.setItem("userData", JSON.stringify(res?.data?.data?.user));
        handleNext(); // Call handleNext on success
      }
    } catch (error) {
      toast.error("Error updating profile");
    }
    setLoading(false);
  };

  if (loading) {
    return (
      <div className="loader-div">
        <img src={load} alt="Logo" className="loader-logo" />
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div className="mission-clip4">
      <SmallCom />
      <div>
        <div className="mission-slot-day-eveing">
          <h3 style={{ textAlign: "left" }}>User Details</h3>
          <br />
          <div className="mission-numbver">
            <label>Full Name</label>
            <input
              style={{ marginTop: "15px" }}
              placeholder="Enter Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <br />
          <div className="mission-numbver">
            <label>Phone Number</label>
            <input
              style={{ marginTop: "15px", backgroundColor: "rgb(211, 211, 211)" }}
              placeholder="Enter Number"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              disabled
            />
          </div>
          <br />
          <div className="mission-numbver">
            <label>Email</label>
            <input
              style={{ marginTop: "15px" }}
              placeholder="Enter Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <br />
        <div className="button-group">
<div className="missionloginbtn-div">

          <button
            className="missionloginbtn missionloginbtn-back"
            onClick={handleBack} // Call handleBack on button click
          >
            Back
          </button>
          <button
            className="missionloginbtn"
            onClick={updateUser} // Call updateUser on button click
          >
            Continue
          </button>
        </div>
        </div>
      </div>
    </div>
  );
};

export default StepTwo;
