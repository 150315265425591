import React, { useState, useEffect } from "react";
import axios from "axios";
import newlogo from "../Assets/newlogo11.png";
import img from '../Assets/output-onlinegiftools.gif'
import img2 from '../Assets/output-onlinegiftools2.gif'
import img3 from '../Assets/bowl.gif'
import img4 from "../Assets/cd1.png";
import load from "../Assets/newlogo.png"; // Custom loader image
import { baseUrl } from "../utils/data"; // Assuming you have this utility
import { useNavigate, useParams } from "react-router-dom";
import mission from '../Assets/WhatsApp Image 2024-12-14 at 4.23.51 PM.jpeg';

function Missionawesome() {
  const { id } = useParams();
  const [allProduct, setAllProduct] = useState([]);
  const [selectedcatname , setselectedcatname] = useState('');
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  console.log(id);
  console.log(allProduct[0]);
  
  console.log(selectedcatname);

  const getAllProduct = async () => {
    try {
      const res = await axios.get(`${baseUrl}/product/all`);
      console.log(res)
      const selectedProduect = res?.data?.data?.filter((ele) => ele?.category?._id === id) || []
      setAllProduct(
        selectedProduect

      );
      if(selectedProduect && selectedProduect.length>0){
        setselectedcatname(selectedProduect[0]?.category?.name)
      }
      
    } catch (error) {
      console.error("Error fetching products", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllProduct();
  }, []);

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    localStorage.setItem("selectedProduct", JSON.stringify(product));
    console.log("Product saved to LocalStorage:", product);
    navigate("/mission500/subscribe");
  };

  if (loading) {
    return (
      <div className="loader-div">
        <img src={load} alt="Logo" className="loader-logo" />
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div className="misson-div misson-div2">
      <img className="ig1 ig11" src={img} alt="Orange" />
      <img className="ig2 ig22" src={img2} alt="Pineapple Juice" />
      <img className="ig3" src={img3} alt="New" />

      <div className="misson-div-left">
        <div className="newlogo-div">
          <img className="juice-logo" src={newlogo} />
          <img className="mission-logo" src={mission} />
        </div>
        <h2>
  {selectedcatname === "Juices" && "Fantastic choice !!!"}
  {selectedcatname === "Cut Fruit" && "Amazing choice !!!"}
  {selectedcatname === "Whole Fruit" && "Wonderful choice"}
  {!["Juices", "Cut Fruit", "Whole Fruit"].includes(selectedcatname) && "Amazing choice !!!"}
</h2>
        <br/>
        <br/>
      </div>

      <div className="misson-div-right misson-div-right2">
        <div className="mission-clip3">
          <div className="Awesome-text">
            <p>
            {selectedcatname === "Juices" && "Juices are the best convenient way to consume fruits and with our cold pressed juicing process we bring you as close as possible to experience the natural goodness of fresh juices."}
  {selectedcatname === "Cut Fruit" && "Most preferred option by our subscribers we will do everything we can to bring you the freshest and most nutritious fruit experiences"}
  {selectedcatname === "Whole Fruit" && "It is the most ideal way to consume fruits and we will do everything we can to make it a habit, affordable and convenient for you"}
  {!["Juices", "Cut Fruit", "Whole Fruit"].includes(selectedcatname) && "It is the most ideal way to consume fruits and we will do everything we can to make it a habit, affordable and convenient for you"}
            </p>
          </div>
          <br/>
          <p>
          {selectedcatname === "Juices" && "Subscriptions available"}
  {selectedcatname === "Cut Fruit" && "Subscriptions available"}
  {selectedcatname === "Whole Fruit" && "Subscriptions available"}
  {!["Juices", "Cut Fruit", "Whole Fruit"].includes(selectedcatname) && ""}
          {/* What is your preferred fruit intake choice? */}
          </p>
          <br/>

          <div className="asw-cards">
            {allProduct.length > 0 ? (
              allProduct.map((product) => (
                <div
                  className={`asw-card3 ${selectedProduct?._id === product._id ? "selected" : ""}`}
                  key={product._id}
                  onClick={() => handleProductClick(product)}
                >
                 {/* <p><b>
                 {selectedcatname === "Whole Fruit" && "Ui Is Being Designed and Developed"}
                 {!["Juices", "Cut Fruit", "Whole Fruit"].includes(selectedcatname) && "Ui Is Being Designed and Developed"}
                 </b></p> */}

                <div className="juice-div">
                <img src={product.imageUrl || img4} alt="Juices" />
                <p><b>{product.name}</b></p>
                </div>

                 <div>
             
             <p>{product.description}</p>
                 </div>
               
                </div>
              ))
            ) : (
              <p><b>“UI Is Being
              Designed and Developed”</b><br/>

Contact us to subscribe this
product 
<br/>
<br/>
<p className="bi-wa">
<i class="bi bi-telephone-fill"></i>
<i class="bi bi-whatsapp"></i>
+91 8977037763
</p></p>
            )}
          </div>
<br/>
<br/>
<br/>
          <img className="slidingorange2" src={img}/>
        </div>
      </div>
    </div>
  );
}

export default Missionawesome;


// import React from 'react'
// import orange from '../Assets/pngwing.com (34) 1.png'
// import newlogo from '../Assets/newlogo11.png'

// import img from '../Assets/967_417_Orange_Color_Stock_Photos__High-Res_Pictures__and_Images-removebg-preview.png'
// import img2 from '../Assets/Pineapple_Juice-removebg-preview.png'
// import img3 from '../Assets/new-removebg-preview.png'
// import img4 from '../Assets/cd1.png'

// function Missionawesome() {
//   return (
//     <>
//     <div className='misson-div misson-div2'>
//     <img className='ig1 ig11' src={img}/>
//     <img className='ig2 ig22' src={img2}/>
//     <img className='ig3' src={img3}/>

//   <div className='misson-div-left'>
//   <div className='newlogo-div'>
// <img src={newlogo}/>
//     <div className='misson-logo'>

// <p>Mission<br/>500</p>
//     </div>
//   </div>
//     <h2>Awesome!!!</h2>

//   </div>
//   <div className='misson-div-right'>
//   <div className='mission-clip3'>

//   <div className='Awesome-text'>
//   <p>Your clearly have a great approach to balanced intake ,and we’re here to make it effortlessly easy and surprisingly affordable for you.</p>

//   </div>
//   <br/>
//   <br/>

//   <br/>

//   <p>What is you preferred fruit intake choice?</p>
//   <br/>
//   <div className='asw-cards'>
//     <div className='asw-card'>
//     <img src={img4}/>
// <p>Juices</p>
//     </div>
//     <div className='asw-card'>
//     <img src={img4}/>
// <p>Juices</p>
//     </div>
//     <div className='asw-card'>
//     <img src={img4}/>
// <p>Juices</p>
//     </div>
//   </div>
//   </div>

//   </div>

//     </div>
//    </>
//   )
// }

// export default Missionawesome
