import React, { useState } from "react";
import newbanner from "../Assets/newbanner.png";
import check from "../Assets/CheckCircle.png";
import { Modal, ModalBody } from "reactstrap";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import img from "../Assets/967_417_Orange_Color_Stock_Photos__High-Res_Pictures__and_Images-removebg-preview.png";
import img2 from "../Assets/Pineapple_Juice-removebg-preview.png";
import img3 from "../Assets/new-removebg-preview.png";

function Missonslot() {
  const [currentStep, setCurrentStep] = useState(1);
  const [modal, setmodal] = useState(false);

  const steps = ["Login", "OTP", "Details", "Address", "Payment"];

  const handleNext = () => {
    if (currentStep < steps.length) {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep((prevStep) => prevStep - 1);
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 1:
        return <StepOne step={step} handleNext={handleNext} handleBack={handleBack}/>;
      case 2:
        return <StepOne step={step} handleNext={handleNext} />;
      case 3:
        return <StepTwo step={step} handleNext={handleNext} handleBack={handleBack} />;
      case 4:
        return <StepThree step={step} handleNext={handleNext} handleBack={handleBack} />;
      case 5:
        return <StepFour step={step} handleNext={handleNext} handleBack={handleBack} />;
      default:
        return <StepOne step={step} handleNext={handleNext} />;
    }
  };

  return (
    <>
      <img className="ig1 ig11" src={img} alt="Orange Juice" />
      <img className="ig2 ig22" src={img2} alt="Pineapple Juice" />
      <img className="ig3" src={img3} alt="Juice Banner" />
      <div className="stepper-div">
        <div className="stepper">
          <div className="step-container">
            {steps.map((step, index) => (
              <div key={index} className="step-wrapper">
                <div
                  className={`circle ${
                    currentStep >= index + 1 ? "active" : ""
                  }`}
                ></div>
                {index !== steps.length - 1 && (
                  <div
                    className={`line ${
                      currentStep > index + 1 ? "active-line" : ""
                    }`}
                  ></div>
                )}
                <p
                  className={`step-label ${
                    currentStep >= index + 1 ? "active-label" : ""
                  }`}
                >
                  {step}
                </p>
              </div>
            ))}
          </div>

          <div className="step-content">
            {renderStepContent(currentStep)}
            <div className="navigation-buttons">
            {currentStep > 1 && (
                <button
                  onClick={handleBack}
                  className="back-button"
                  style={{background:'none'}}
                >
                
                </button>
              )}
              {/* {currentStep < steps.length && (
                <button
                  onClick={handleNext}
                  className="next-button"
                >
                  Next
                </button>
              )} */}
            </div>
          </div>
        </div>
      </div>
      <Modal size="lg" isOpen={modal} toggle={() => setmodal(!modal)}>
        <ModalBody className="modala">
          <img src={check} alt="Checkmark" />
          <h3>Your Order Is Successfully Complete</h3>
          <h4>Fruit Juices</h4>
          <div className="slot-banner">
            <img src={newbanner} alt="Slot Banner" />
            <div className="slot-banner-up">
              <p style={{ textAlign: "center" }}>Trail / 1m</p>
              <h3 style={{ textAlign: "center" }}>Rs 650 / 2599</h3>
              <p style={{ textAlign: "center" }}>Slot - Morning</p>
              <p style={{ textAlign: "center" }}>Start Date - 20/02/2024</p>
            </div>
          </div>
          <br />
          <button>Back To Home</button>
        </ModalBody>
      </Modal>
    </>
  );
}

export default Missonslot;
