import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import About from "./Pages/About";
import OurProducts from "./Pages/OurProducts";
import ProductDetails from "./Pages/ProductDetails";
import CheckOut from "./Pages/CheckOut";
import Shipping from "./Pages/Shipping";
import Payment from "./Pages/Payment";
import PaymentSuccessful from "./Pages/PaymentSuccessful";
import Profile from "./Pages/Profile";
import Dashboard from "./Pages/Dashboard";
import Contact from "./Pages/Contact";
import Plans from "./Pages/Plans";
import NewProduct from "./Pages/NewProduct";
import LoginSignupCard from "./Pages/LoginSignupCard";
import SubProductDetails from "./Pages/SubProductDetails";
import TermsandCondition from "./Pages/TermsandCondition";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import ShippingPolicy from "./Pages/ShippingPolicy";
import ReturnnRefund from "./Pages/ReturnnRefund";
import TrialPackDialog from "./Components/TrialPackDialog";
import MissionHome from "./Mission/MissionHome";
import MissionFact from "./Mission/MissionFact";
import MissionConcerning from "./Mission/MissionConcerning";
import Missionawesome from "./Mission/Missionawesome";
import MissionChoice from "./Mission/MissionChoice";
import MissionSubscribe from "./Mission/MissionSubscribe";
import Missonslot from "./Mission/Missonslot";
import MissionDeliveryAddress from "./Mission/MissionDeliveryAddress";
import 'bootstrap/dist/css/bootstrap.css'
import MissionChoiceTwo from "./Mission/MissionChoiceTwo";



function App() {
  
  return (
   <>
      <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/OurProducts" element={<OurProducts />} />
        <Route path="/ProductDetails/:id" element={<ProductDetails />} />
        <Route path="/SubProductDetails/:id" element={<SubProductDetails />} />
        <Route path="/trial-pack" element={<TrialPackDialog />} />
        <Route path="/CheckOut" element={<CheckOut />} />
        <Route path="/Shipping" element={<Shipping />} />
        <Route path="/PaymentSuccessfu" element={<Payment />} />
        <Route path="/Payment" element={<PaymentSuccessful />} />
        <Route path="/Profile" element={<Profile />} />
        <Route path="/Plans" element={<Plans />} />
        <Route path="/Dashboard" element={<Dashboard />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/newproduct" element={<NewProduct />} />
        <Route path="/Login" element={<LoginSignupCard />} />
        <Route path="/termsncondition" element={<TermsandCondition />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/shippingpolicy" element={<ShippingPolicy />} />
        <Route path="/returnnrefund" element={<ReturnnRefund />} />
        <Route path="/mission500/home" element={<MissionHome />} />
        <Route path="/mission500/fact" element={<MissionFact />} />
        <Route path="/mission500/concerning" element={<MissionConcerning />} />
        <Route path="/mission500/choice" element={<MissionChoice />} />
        <Route path="/mission500/choicetwo" element={<MissionChoiceTwo />} />

        <Route path="/mission500/awesome/:id" element={<Missionawesome />} />
        <Route path="/mission500/subscribe" element={<MissionSubscribe />} />
        <Route path="/mission500/slot" element={<Missonslot />} />
        <Route path="/deliveryaddress" element={<MissionDeliveryAddress />} />






        












      




        



        




        
        
    




  
      </Routes>
    </Router>
   </>
  );
}

export default App;
