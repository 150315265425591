import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // To navigate to the next page
import orange from "../Assets/pngwing.com (34) 1.png";
import img from '../Assets/output-onlinegiftools.gif';
import img2 from '../Assets/output-onlinegiftools2.gif';
import img3 from '../Assets/bowl.gif';
import img4 from "../Assets/cd1.png";
import newlogo from "../Assets/newlogo11.png";
import icon from "../Assets/righticon.png";
import newbanner from "../Assets/newbanner.png";
import mission from '../Assets/WhatsApp Image 2024-12-14 at 4.23.51 PM.jpeg';

function MissionSubscribe() {
  const [selectedProduct, setSelectedProduct] = useState(null); // To store the product data from localStorage
  const [subscriptionType, setSubscriptionType] = useState(""); // To store subscription type
  const [errorMessage, setErrorMessage] = useState(""); // To display error messages
  const navigate = useNavigate(); // To navigate to the next page

  // Retrieve product data from localStorage when the component mounts
  useEffect(() => {
    const storedProduct = JSON.parse(localStorage.getItem("selectedProduct"));
    if (storedProduct) {
      setSelectedProduct(storedProduct);
    }
    if (storedProduct?.subscriptionType) {
      setSubscriptionType(storedProduct.subscriptionType);
    }
  }, []);

  // Function to handle subscription selection
  const handleSubscription = (type) => {
    setSubscriptionType(type); // Set the subscription type in state
    setErrorMessage(""); // Clear any error messages
  };

  // Function to handle "Continue" button click
  const handleContinue = () => {
    if (!subscriptionType) {
      setErrorMessage("Please select a subscription type before continuing!");
      return; // Prevent navigation if no subscription is selected
    }

    if (selectedProduct) {
      // Add or update the subscriptionType in the product data
      const updatedProduct = { ...selectedProduct, subscriptionType };
      localStorage.setItem("selectedProduct", JSON.stringify(updatedProduct)); // Save the updated product to localStorage
      console.log("Updated Product:", updatedProduct);

      // Navigate to the next page
      navigate("/mission500/slot"); // Replace "/nextpage" with your desired route
    } else {
      alert("No product selected!");
    }
  };

  return (
    <>
      <div className="misson-div misson-div2">
        <img className="ig1 ig11" src={img} />
        <img className="ig2 ig22" src={img2} />
        <img className="ig3" src={img3} />

        <div className="misson-div-left">
          <div className="newlogo-div">
            <img className="juice-logo" src={newlogo} />
            <img className="mission-logo" src={mission} />
          </div>
          <h2>{selectedProduct?.name || ""}</h2>
        </div>
        <br />
        <br />
        <br />

        <div className="misson-div-right">
          <div className="mission-clip3">
            {/* Show selected product details */}
            {selectedProduct ? (
              <div className="selected-product-details">
                <div className="mission-fact mission-fact121">
                  <p>
                    <img src={icon} /> Freshly prepared
                  </p>
                  <p>
                    <img src={icon} /> Cold pressed
                  </p>
                  <p>
                    <img src={icon} /> Free delivery
                  </p>
                </div>
                <br />

                <h4>Selected Product</h4>
                <br />
                <div className="selectedpd-div">
                  <div className="selectedpd">
                    <img
                      src={selectedProduct.imageUrl || img4}
                      alt="Selected Product"
                      width={"90px"}
                      height={"90px"}
                    />
                    <p style={{ marginTop: "10px", textAlign: "center" }}>
                      <b>{selectedProduct.name}</b>
                    </p>
                    <p style={{ marginTop: "10px", textAlign: "center" }}>
                      {selectedProduct.description}
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <p>No product selected.</p>
            )}

            <div className="line3"></div>
            <br />

            <h4>Choose Your Subscription Type</h4>
            <br />
            <div className="subscribe-btns">
              {/* Subscription buttons */}
              <button
                className={`month-trial ${subscriptionType === "Monthly" ? "active" : ""}`}
                onClick={() => handleSubscription("Monthly")}
              >
                1 Month - 26 Products
              </button>
              <button
                className={`month-trial ${subscriptionType === "Trial" ? "active" : ""}`}
                onClick={() => handleSubscription("Trial")}
              >
                Trial - 6 Products
              </button>
            </div>
            <br />

            {/* Display error message */}
            {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}

            {/* Display selected subscription type */}
            {subscriptionType && (
              <p>
                You have selected the <strong>{subscriptionType}</strong> subscription.
              </p>
            )}
            <br />

            {/* Continue button */}
            <button className="continue-button" onClick={handleContinue}>
              Continue
            </button>
            <br />
          </div>
        </div>
      </div>
    </>
  );
}

export default MissionSubscribe;
