import React, { useState, useEffect } from "react";
import newbanner from "../Assets/newbanner.png";
import morning from "../Assets/morn.png";
import evening from "../Assets/eve.png";
import axios from "axios";
import { toast } from "react-toastify";
import { baseUrl } from "../utils/data";
import load from "../Assets/newlogo.png";
import SmallCom from "./SmallCom";

const StepFour = ({ step, handleNext, handleBack }) => {
  const [loading, setLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const [idAddress, setIdAddress] = useState("");

  useEffect(() => {
    const storedProduct = JSON.parse(localStorage.getItem("selectedProduct"));
    if (storedProduct) {
      // Convert startDate to input format (YYYY-MM-DD) if it exists
      if (storedProduct.startDate) {
        const date = new Date(storedProduct.startDate);
        storedProduct.startDate = date.toISOString().split("T")[0]; // Extract YYYY-MM-DD
      }
      setSelectedProduct(storedProduct);
    }
    // fetchAddresses();
  }, []);
  

  const fetchAddresses = async () => {
    try {
      const response = await axios.get(`${baseUrl}/user/bytoken`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });
      if (response.status == 200) {
        setAddresses(response?.data?.data?.deliveryAddress);
      }
    } catch (error) {
      console.error("Error fetching addresses:", error);
    }
  };

  const handleSelectAddress = async (addressId) => {
    // console.log(addresses);
    // console.log(addressId);
    const selectedAddress = addresses.find(
      (address) => address._id === addressId
    );
    // console.log(selectedAddress);
    if (!selectedAddress) {
      return toast.error("Arress Not Found.");
    }
    const deliveryAddress = `${selectedAddress.address}, ${selectedAddress.pincode}`;
    const deliveryLocation = selectedAddress.location;
    setSelectedProduct((prev) => ({
      ...prev,
      deliveryAddress,
      deliveryLocation
    }));
    setIdAddress(addressId);
  };

  const handlePay = async () => {
    const userData = JSON.parse(localStorage.getItem("userData")) || null;
    let total =
      selectedProduct?.subscriptionType == "Monthly"
        ? selectedProduct?.price
        : selectedProduct?.trialPack;
    if (userData) {
      const { name, mobile } = userData;
      if (!name || !mobile) {
        toast.error("Please Update Your Profile First.");
        return;
      }
      if (
        !selectedProduct.deliveryAddress ||
        !selectedProduct.deliveryLocation
      ) {
        toast.error(
          "Please select delivery addresses for all items in your cart."
        );
        return;
      }
      if (!selectedProduct.startDate) {
        toast.error("Please select a start date.");
        return;
      }
      if (!selectedProduct.slot) {
        toast.error("Please select a slot.");
        return;
      }
      if (total <= 0) {
        return toast.error("Amount should be greater than Zero");
      }
      let produtctupdate = selectedProduct;
      produtctupdate.product = {
        _id: selectedProduct?._id,
        quantity: 1
      };
      produtctupdate.orderAmount = total;
      produtctupdate.packageType = "onetimeuse";

      let payload = {};
      let apiUrl = "";

      if (selectedProduct?.subscriptionType === "Monthly") {
        payload = {
          subscriptions: [{ ...produtctupdate }]
        };
        apiUrl = `${baseUrl}/subscription/newcreate`;
      } else if (selectedProduct?.subscriptionType === "Trial") {
        const { packingAmount = 0 } = selectedProduct;
        payload = {
          productId: selectedProduct._id,
          startDate: selectedProduct.startDate,
          deliveryAddress: selectedProduct.deliveryAddress,
          slot: selectedProduct.slot,
          packageType: "onetimeuse",
          deliveryLocation: selectedProduct.deliveryLocation,
          orderAmount: total,
          packingAmount: packingAmount
        };
        apiUrl = `${baseUrl}/subscription/trial`; // Trial subscription URL
      }
      setLoading(true);
      try {
        const res = await axios.post(apiUrl, payload, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        });
        if (res?.status == 200) {
          const orderId =
            selectedProduct?.subscriptionType === "Monthly"
              ? res?.data?.data[0]?.batchId
              : res?.data?.data?.batchId;

          console.log(res);
          const result = await axios.post(`${baseUrl}/phonepe/phoneperequest`, {
            amount: total,
            merchantUserId: orderId
          });
          if (result?.status == 200) {
            window.location.href = result?.data;
          }
        }
      } catch (error) {
        toast.error("Buy Subscription Failed.");
      }
      setLoading(false);
    } else {
      toast.error("Please Try Again.");
    }
  };

  return (
    <div className="mission-clip4">
      <SmallCom />
      <br />

      <div className="mission-slot-day-eveing">
        <h3 style={{ textAlign: "left" }}>Slot</h3>
        <div className="radio-buttons">
          <input
            type="radio"
            id="option1"
            name="radio-group"
            className="radio-input"
            value="Morning"
            checked={selectedProduct?.slot === "Morning"}
            onChange={() => {
              setSelectedProduct((prev) => ({ ...prev, slot: "Morning" }));
            }}
          />
          <label htmlFor="option1" className="radio-label">
            <i class="bi bi-brightness-high"></i>
            Morning
          </label>

          <input
            type="radio"
            id="option2"
            name="radio-group"
            className="radio-input"
            value="Evening"
            checked={selectedProduct?.slot === "Evening"}
            // onChange={() => setSlot("Evening")}
            onChange={() => {
              setSelectedProduct((prev) => ({ ...prev, slot: "Evening" }));
            }}
          />
          <label htmlFor="option2" className="radio-label">
            <i class="bi bi-brightness-alt-high"></i>
            Evening
          </label>
        </div>

        <div>
          <h3 style={{ textAlign: "left" }}>Date</h3>
          <input
            type="date"
            className="selectdate"
            value={selectedProduct?.startDate}
            onChange={(e) => {
              setSelectedProduct((prev) => ({
                ...prev,
                startDate: e.target.value
              }));
            }}
            min={new Date().toISOString().split("T")[0]} // Disable past dates
          />
          {/* <h3 style={{ textAlign: "left" }}>Select Address</h3>
          <select
            type="date"
            value={idAddress}
            className="selectdate"
            onChange={(e) => {
              handleSelectAddress(e.target.value);
            }}>
            <option value="">Select Address</option>
            {addresses &&
              addresses.length > 0 &&
              addresses.map((address) => (
                <option value={address?._id}>
                  {address.address}, {address.pincode}
                </option>
              ))}
          </select> */}
        </div>
      </div>
      <br />

      <div className="duplicate-data">
        <h3>
          <span>Subcription Type:</span> {selectedProduct?.subscriptionType}
        </h3>
        <h3>
          <span>Slot:</span> {selectedProduct?.slot}
        </h3>
        <h3>
          <span>Date:</span> {selectedProduct?.startDate}
        </h3>
        <h3>
          <span>Address:</span> {selectedProduct?.deliveryAddress}
        </h3>
        <h3>
          <span>Location:</span> lat :{selectedProduct?.deliveryLocation?.lat},
          lng :{selectedProduct?.deliveryLocation?.lng}{" "}
        </h3>
        <h3>
          <span>Order Amount :</span>{" "}
          {selectedProduct?.subscriptionType == "Monthly"
            ? selectedProduct?.price
            : selectedProduct?.trialPack}
        </h3>
      </div>

      <br />

      <div className="missionloginbtn-div">
        <button
          className="missionloginbtn missionloginbtn-back"
          onClick={handleBack} // Call handleBack on button click
        >
          Back
        </button>
        <button
          style={{ backgroundColor: "#EF4136" }}
          className="missionloginbtn"
          onClick={handlePay}>
          Pay Now
        </button>
      </div>
    </div>
  );
};

export default StepFour;

{
  /* <div className="mission-slot-day-eveing">
        <h3 style={{ textAlign: "left" }}>Payment Method</h3>
        <h4>Cards</h4>
        <br />
        <div className="upi">
          <div className="upi-div">
            <i class="bi bi-credit-card"></i>
            <h4>Credit / Debit card</h4>
          </div>
          <i class="bi bi-caret-right"></i>
        </div>
      </div> */
}

{
  /* <div className="mission-slot-day-eveing">
        <h4>UPI</h4>
        <br />
        <div className="upi">
          <div className="upi-div">
            <i class="bi bi-credit-card"></i>
            <h4>Credit / Debit card</h4>
          </div>
          <i class="bi bi-caret-right"></i>
        </div>
      </div>
      <br />

      <div className="mission-slot-day-eveing">
        <h4>Wallets</h4>
        <br />
        <div className="upi">
          <div className="upi-div">
            <i class="bi bi-credit-card"></i>
            <h4>Credit / Debit card</h4>
          </div>
          <i class="bi bi-caret-right"></i>
        </div>
      </div>
      <br />

      <div className="mission-slot-day-eveing">
        <h4>Netbanking</h4>
        <br />
        <div className="upi">
          <div className="upi-div">
            <i class="bi bi-credit-card"></i>
            <h4>Credit / Debit card</h4>
          </div>
          <i class="bi bi-caret-right"></i>
        </div>
      </div>

      <br />

      <div className="mission-slot-day-eveing">
        <div className="upi">
          <h5>Total</h5>
          <h6>Rs. 650</h6>
        </div>
      </div>
      <br /> */
}
