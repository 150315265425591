import React, { useEffect, useState } from "react";
import newbanner from "../Assets/newbanner.png";
import morning from "../Assets/morn.png";
import evening from "../Assets/eve.png";
import axios from "axios";
import { toast } from "react-toastify";
import { baseUrl } from "../utils/data"; // Make sure this path is correct
import SmallCom from "./SmallCom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const StepOne = ({ step, handleNext }) => {
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [slot, setSlot] = useState("");
  
  const [startDate, setStartDate] = useState(null); // Set initial value as null

  useEffect(() => {
    const storedProduct = JSON.parse(localStorage.getItem("selectedProduct"));
    if (storedProduct) {
      setSelectedProduct(storedProduct);
      setSlot(storedProduct.slot || "");
      setStartDate(storedProduct.startDate || null); // Ensure startDate is set from stored product
    }
  }, []);
  const filterPassedDates = (date) => {
    const day = date.getDay(); // Sunday is 0
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return day !== 0 && date > today; // Disable Sundays and today's date
  };

  useEffect(() => {
    if (selectedProduct) {
      const updatedProduct = { ...selectedProduct, slot };
      localStorage.setItem("selectedProduct", JSON.stringify(updatedProduct));
      setSelectedProduct(updatedProduct);
    }
  }, [slot]);

  useEffect(() => {
    if (selectedProduct && startDate) {
      const updatedProduct = { ...selectedProduct, startDate };
      localStorage.setItem("selectedProduct", JSON.stringify(updatedProduct));
      setSelectedProduct(updatedProduct);
    }
  }, [startDate, selectedProduct]);

  const handleSendOtp = async () => {
    if (mobile.length !== 10) {
      return toast.error("Mobile Number should be 10 digits.");
    }
    setLoading(true);
    try {
      const response = await axios.post(`${baseUrl}/auth/login`, { mobile });
      if (response.status === 200) {
        setOtpSent(true);
        toast.success("OTP sent successfully");
        handleNext();
      }
    } catch (error) {
      toast.error("Failed to send OTP");
    }
    setLoading(false);
  };

  const handleVerifyOtp = async () => {
    if (otp.length !== 4) {
      return toast.error("OTP should be 4 digits.");
    }
    setLoading(true);
    try {
      const response = await axios.post(`${baseUrl}/auth/verify`, {
        mobile,
        otp,
      });
      if (response.status === 200) {
        toast.success("Login successful");
        localStorage.setItem("userData", JSON.stringify(response.data.data.user));
        localStorage.setItem("token", response.data.data.token);
        handleNext(); // Proceed to the next step after successful verification
      }
    } catch (error) {
      toast.error("Invalid OTP");
    }
    setLoading(false);
  };
  const [timeLeft, setTimeLeft] = useState(117); // 1 min 57 secs = 117 seconds
  const [canResend, setCanResend] = useState(false);

  useEffect(() => {
    if (timeLeft === 0) {
      setCanResend(true); // Enable resend OTP when time is up
      return; // Stop the countdown
    }

    const timer = setInterval(() => {
      setTimeLeft(prevTime => prevTime - 1);
    }, 1000);

    return () => clearInterval(timer); // Clean up the interval on component unmount
  }, [timeLeft]);

 
  const formatTime = (time) => {
    // Format the time to show minutes:seconds
    return `${Math.floor(time / 60)}:${time % 60 < 10 ? "0" : ""}${time % 60}`;
  };

  const handleResendOTP = () => {
    // Your logic for resending OTP
  };

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (value.match(/[0-9]/)) {
      // Update the OTP state with the new value at the correct index
      const newOtp = otp.split("");
      newOtp[index] = value;
      setOtp(newOtp.join(""));

      // Manually focus the next input after typing
      const nextInput = document.getElementById(`otp-input-${index + 1}`);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    // Handle Backspace key to move to the previous input
    if (e.key === "Backspace" && index > 0) {
      const prevInput = document.getElementById(`otp-input-${index - 1}`);
      if (prevInput) {
        prevInput.focus();
      }
    }
  };

  return (
    <>
      {step === 1 && (
        <div className="mission-clip4">
          <SmallCom />
          <div className="mission-slot-day-eveing">
            <h3 style={{ textAlign: "left", color: "black" }}>Chosee Your Slot</h3>
            <div className="radio-buttons">
              <input
                type="radio"
                id="option1"
                name="radio-group"
                className="radio-input"
                value="Morning"
                checked={slot === "Morning"}
                onChange={() => setSlot("Morning")}
              />
              <label htmlFor="option1" className="radio-label">
                <i className="bi bi-brightness-high"></i> Morning
              </label>

              <input
                type="radio"
                id="option2"
                name="radio-group"
                className="radio-input"
                value="Evening"
                checked={slot === "Evening"}
                onChange={() => setSlot("Evening")}
              />
              <label htmlFor="option2" className="radio-label">
                <i className="bi bi-brightness-alt-high"></i> Evening
              </label>
            </div>

            <h3 style={{ textAlign: "left", color: "black", marginTop: "25px" }}>
            Select Start Date
            </h3>
            <div className="step-date-div">
            
            <DatePicker
  selected={startDate}
  onChange={(date) => setStartDate(date)} // Bind to state
  filterDate={filterPassedDates} // Filter out Sundays and today's date
  dateFormat="dd/MM/yyyy"
  placeholderText="Select Start Date"
  className="step-date"
 
/>

<i class="bi bi-calendar-event"></i>
            </div>
        

            <h3 style={{ textAlign: "left", color: "black", marginTop: "25px" }}>
              Login/Sign up
            </h3>
            <input
              className="selectdate"
              type="text"
              placeholder="Enter Number"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
            />
          </div>
          <br/>
     
          <button
            className="missionloginbtn"
            onClick={handleSendOtp}
            disabled={loading}
          >
            Send OTP {loading && <span>Loading...</span>}
          </button>
        </div>
      )}

      {step === 2 && (
        <div className="stp2 mission-clip4">
  <SmallCom />
  <div className="mission-slot-day-eveing">
    <h3 style={{ textAlign: "left" }}>Verify Details</h3>
    <div className="verify-otp">
    <input
        type="text"
        id="otp-input"
        onChange={(e) => setOtp(e.target.value)} // Update OTP state
        value={otp} // Bind the value to state
        maxLength={6} // Ensure only the required length is allowed
        autoFocus
        placeholder="Enter Your OTP"

      />
    
    </div>
    <div>
      <p className="otptime">
        <span style={{ color: '#EF4136' }}>{formatTime(timeLeft)}{" "}</span>
        {canResend ? (
          <button className="resend" onClick={handleResendOTP}>Click Here To Resend OTP</button>
        ) : (
          "Click Here To Resend OTP"
        )}
      </p>
    </div>
  </div>
  <div className="missionloginbtn-div">

  <button
    className="missionloginbtn"
    onClick={handleVerifyOtp}
    disabled={loading}
  >
    Verify OTP {loading && <span>Loading...</span>}
  </button>
</div>
</div>
      )}
    </>
  );
};

export default StepOne;
