import React from 'react'
import orange from '../Assets/pngwing.com (33) 1.png'
import newlogo from '../Assets/newlogo11.png'
import img from '../Assets/output-onlinegiftools.gif'
import img2 from '../Assets/output-onlinegiftools2.gif'
import img3 from '../Assets/bowl.gif'
import mission from '../Assets/WhatsApp Image 2024-12-14 at 4.23.51 PM.jpeg'
import every from '../Assets/every.jpeg'
import { useNavigate } from 'react-router-dom'
function MissionFact() {
  const navigate = useNavigate()
  return (
    <>
    {/* <div className='misson-div'>
    <img className='ig1 ig11' src={}/>
    <img className='ig2 ig22' src={img2}/>
    <img className='ig3 ig3121' src={img}/>


  <div className='misson-div-left'>
  <div className='newlogo-div'>
<img className='juice-logo' src={newlogo}/>
    <img className='mission-logo' src={mission}/>
    <div className='misson-logo'>
    </div>
  </div>
    <h2 className='doyouknow'>MISSION 500 ???</h2>

  </div>
  <div className='misson-div-right'>
  <div className='mission-clip mission-clip2'>
  <img className='mission-clip212' src={img3}/>
  <br/>
  <br/>
 <div className='missionpara'>
    <p>We want you to consume 500g
everyday and now we have made
this our MISSION</p>
 </div>
 <br/>
 
<p className='fruitconsumer'>Are you a regular fruit consumer?</p>
<br/>

<div className='mission-btns'>
    <button className='mission-btns-yes' onClick={()=>navigate('/mission500/choice')}>Yes</button>
    <button className='mission-btns-no' onClick={()=>navigate('/mission500/concerning')} >No</button>

</div>

  </div>

  </div>
    

    </div> */}
    <div className='new-misson-div'>
    <div className='mission-heading'>
    <img className='juice-logo' src={newlogo}/>
    <img className='mission-logo' src={mission}/>
    </div>
    <br/>
    <br/>
    <div className='newcenter'>
      <h3>MISSION 500 ???</h3>
    </div>
    <img className='everyday' src={every}/>
    <br/>
    <br/>
    <br/>
<div className='white-back'>
<div className='og-col'>
<p>We want you to consume 500g everyday and now we have made this our MISSION</p>

</div>
    <br/>

<h3>Are you a regular fruit consumer?</h3>
<br/>

<div className='mission-btns'>
    <button className='mission-btns-yes' onClick={()=>navigate('/mission500/choice')}>Yes</button>
    <button className='mission-btns-no' onClick={()=>navigate('/mission500/concerning')} >No</button>

</div>
<br/>


</div>

    </div>
   </>
  )
}

export default MissionFact