import React from 'react'
import orange from '../Assets/worry.png'
import newlogo from '../Assets/newlogo11.png'
import mission from '../Assets/WhatsApp Image 2024-12-14 at 4.23.51 PM.jpeg'
import img from '../Assets/output-onlinegiftools.gif'
import img2 from '../Assets/output-onlinegiftools2.gif'
import img3 from '../Assets/bowl.gif'
import { useNavigate } from 'react-router-dom'
import orange1 from '../Assets/output-onlinegiftools2.gif'
import img0 from '../Assets/neworg.gif'

function MissionConcerning() {
  const navigate = useNavigate()
  return (
    <>
    {/* <div className='misson-div'>
    <img className='ig1 ig11' src={img}/>
    <img className='ig2 ig22' src={img2}/>
    <img className='ig3' src={img3}/>


  <div className='misson-div-left'>
  <div className='newlogo-div'>
  <img className='juice-logo' src={newlogo}/>
  <img className='mission-logo' src={mission}/>
  </div>
    <h2>That’s Concerning!!!</h2>

  </div>
  <div className='misson-div-right'>
  <div className='mission-clip mission-clip2'>
  <img className='worryfruit' src={orange}/>
  
 <div className='missionpara'>
    <p>But No worries, We got you covered!</p>
 </div>
 <br/>
<p className='fruitconsumer'>Are you
ready to consume 500g of
fresh fruit as a part of your
daily routine with Mission
500?</p>
<br/>





<div className='mission-btns'>

 

</div>
<br/>
  </div>
   <div className='sct-div'>
   <img className='sct' src={orange1}/>
  <button style={{width:'100%'}} className='mission-btns-yes' onClick={()=>navigate('/mission500/choice')}>Yes</button>
    <button className='mission-btns-no' onClick={()=>navigate('/mission500/choice')}>No</button>
   </div>
  </div>
    

    </div> */}

    <div className='new-misson-div'>
    <div className='mission-heading'>
    <img className='juice-logo' src={newlogo}/>
    <img className='mission-logo' src={mission}/>
    </div>
    <br/>
    <br/>
    <div className='newcenter'>
      <h3>That's Concerning!!!</h3>
    </div>
    <br/>
    <br/>
    <img className="slidingorange" src={img0}/>

    <br/>
<div className='white-back'>
<div className='og-col'>
<p>But No worries, We got you covered!</p>

</div>
    <br/>

<h3 style={{color:'black'}}>Are you ready to consume 500g of fresh fruit as a part of your daily routine with Mission 500?</h3>


{/* <div className='mission-btns'>
    <button className='mission-btns-yes' onClick={()=>navigate('/mission500/choicetwo')}>Yes</button>
</div> */}
<br/>
<br/>
<div className='mission-btns2'>
<img className='sct' src={orange1}/>
<button className='nebs' onClick={()=>navigate('/mission500/choicetwo')}>Yes</button>

</div>

<br/>


</div>

    </div>
   </>
  )
}

export default MissionConcerning