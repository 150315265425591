import orange from '../Assets/WhatsApp Image 2024-12-14 at 4.23.51 PM.jpeg'
import React from 'react'
import img from '../Assets/output-onlinegiftools.gif'
import img2 from '../Assets/output-onlinegiftools2.gif'
import img3 from '../Assets/bowl.gif'
import logo from '../Assets/newlogo.png'
import { useNavigate } from 'react-router-dom'
function MissionHome() {
  const navigate = useNavigate()
  return (
   <>
    {/* <div className='misson-div'>
    <img className="ig1 ig11" src={img} alt="Orange" />
      <img className="ig2 ig22" src={img2} alt="Pineapple Juice" />
      <img className="ig3" src={img3} alt="New" />


  <div className='misson-div-left'>
   <img className='logo' src={logo}/>
  </div>
  <div className='misson-div-right'>
  <div className='mission-clip'>
  <img className='new500' src={orange}/>
  
  
  <h3>MISSION 500 <br/>
  <span>
  An initiative by

  <b> THE JUICES</b>
  </span>
    </h3>

  <br/>
  <button onClick={()=>{navigate('/mission500/fact')}} >Get Started</button>

  </div>

  </div>
    

    </div> */}

    <div className='new-misson-div'>
   <img className='new-logo' src={logo}/>
   <br/>
   <br/>
   <br/>
   <br/>
   <br/>
   <br/>
   <br/>
   <br/>
   
   <div className='white-back white-back2'>
   <img className='new500' src={orange}/>
 
   <br/>
   


   <h3>MISSION 500 <br/>
  <span>
  An initiative by

  <b> THE JUICES</b>
  </span>
    </h3>
<br/>

<button onClick={()=>{navigate('/mission500/fact')}}>Get Started</button>
<br/>
<br/>

   </div>

    </div>
   </>
  )
}

export default MissionHome